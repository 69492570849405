import React from "react"
import Layout from "../layouts/de"
import DownloadsPageBase from "../templates/downloads"

export default () => {
    return (
        <Layout>
            <DownloadsPageBase locale="de" />
        </Layout>
    )
}
